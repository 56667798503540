<template>
  <div class="categories">
    <Navbar/>  
    <div class="container">
        <CategorieTitle>
            <template #maintitle>
                Инженерные школы
            </template>
        </CategorieTitle>

        <div class="row school-row">
            <SchoolCard :url="'/schools/engineer-schools/cy'" :background="'image-cy'">
                <template #title>
                    CY Tech
                </template>
                <template #subtitle>
                    популярная инженерная школа, готовящая студентов по самым востребованным специальностям в области информатики, биологии, химии и других наук. Кампусы в пригороде Парижа (Cergy) и Pau. Программы на французском и английском языках.
                </template>
            </SchoolCard>
        </div>
    </div>
  </div>
</template>

<script>

import Navbar from '@/components/common/Navbar.vue'
import SchoolCard from '@/components/categories/slots/SchoolCard.vue'
import CategorieTitle from '@/components/categories/slots/CategorieTitle.vue'

export default {
  name: 'EngineerSchools',
  components: {
    Navbar,
    SchoolCard,
    CategorieTitle
  }
}
</script>
